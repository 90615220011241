import * as H from "history";

import {
  AggregationFunction,
  Channel,
  ChannelIncDel,
  DailyIncentiveLog,
  DailyRow,
  DatabaseUpdateResponse,
  Dow,
  IncentiveParam,
  Occasion,
  QuarterLabelSeries,
  TlbgConcept,
  User,
  ValueTransformer,
  Venue,
  YearlyRow,
} from "../typings/common";

import AuthService from "../services/AuthService";

export const significantProportionThreshold = 0.0;

export const sum = (array: Array<number>) => {
  if (array.length === 0) {
    return -10000;
  }
  return array.reduce((accum, element) => accum + element);
};

export const defaultSalesChannels: Channel[] = [
  {
    label: "Dine-in",
    code: "dine_in",
    codeSales: "dine_in_w_svc|sales",
    codeOrderCount: "channel|dine_in|check_count",
    codeGuestCount: "channel|dine_in|guests",
    active: true,
  },
  {
    label: "Take-out",
    code: "take_out",
    codeSales: "channel|take_out|sales",
    codeOrderCount: "channel|take_out|check_count",
    codeGuestCount: "channel|take_out|guests",
    active: true,
  },
  {
    label: "Delivery",
    code: "delivery",
    codeSales: "channel|delivery|sales",
    codeOrderCount: "channel|delivery|check_count",
    codeGuestCount: "channel|delivery|guests",
    active: true,
  },
  {
    label: "Events",
    code: "events",
    codeSales: "channel|events|sales",
    codeOrderCount: "channel|events|check_count",
    codeGuestCount: "channel|events|guests",
    active: true,
  },
  {
    label: "Others",
    code: "others",
    codeSales: "channel|others|sales",
    codeOrderCount: "channel|others|check_count",
    codeGuestCount: "channel|others|guests",
    active: true,
  },
];

export const dtaChannels: Channel[] = [defaultSalesChannels[2], defaultSalesChannels[1]];

export const deaChannels: Channel[] = [
  defaultSalesChannels[0],
  defaultSalesChannels[3],
  defaultSalesChannels[4],
];

export const defaultMarketingChannels: ChannelIncDel[] = [
  {
    label: "Dine-in",
    code: "dine_in",
    codeSales: "channel|dine_in|sales", // Different from SalesDashboard's
    codeOrderCount: "channel|dine_in|check_count",
    active: true,
  },
  {
    label: "Delivery (Oddle)",
    code: "delivery|oddle",
    codeSales: "delivery|oddle|sales_less_svc",
    codeOrderCount: "delivery|oddle|order_count",
    active: true,
  },
  {
    label: "Delivery (GrabFood)",
    code: "delivery|grabfood",
    codeSales: "delivery|grabfood|sales_less_svc",
    codeOrderCount: "delivery|grabfood|order_count",
    active: true,
  },
  {
    label: "Delivery (Deliveroo)",
    code: "delivery|deliveroo",
    codeSales: "delivery|deliveroo|sales_less_svc",
    codeOrderCount: "delivery|deliveroo|order_count",
    active: true,
  },
  {
    label: "Delivery (FoodPanda)",
    code: "delivery|foodpanda",
    codeSales: "delivery|foodpanda|sales_less_svc",
    codeOrderCount: "delivery|foodpanda|order_count",
    active: true,
  },
  {
    label: "Take-out",
    code: "take_out",
    codeSales: "channel|take_out|sales",
    codeOrderCount: "channel|take_out|check_count",
    active: true,
  },
  {
    label: "Events",
    code: "events",
    codeSales: "channel|events|sales",
    codeOrderCount: "channel|events|check_count",
    active: true,
  },
  {
    label: "Others",
    code: "others",
    codeSales: "channel|others|sales",
    codeOrderCount: "channel|others|check_count",
    active: true,
  },
];

export const marketingDashboardFieldsToAggregate: (keyof DailyRow)[] = [
  "business_date",
  "total_collection",
  "gst",
  "discounts",
  "service_charge",
  "channel|dine_in|sales",
  "delivery|oddle|sales",
  "delivery|grabfood|sales",
  "delivery|deliveroo|sales",
  "delivery|foodpanda|sales",
  "channel|take_out|sales",
  "channel|events|sales",
  "channel|others|sales",
  "product|food|sales",
  "product|beverage|sales",
  "product|condiment|sales",
  "product|miscellaneous|sales",
  "channel|dine_in|check_count",
  "delivery|oddle|order_count",
  "delivery|grabfood|order_count",
  "delivery|deliveroo|order_count",
  "delivery|foodpanda|order_count",
  "channel|take_out|check_count",
  "channel|events|check_count",
  "channel|others|check_count",
  "channel|dine_in|guests",
];

export const safeSum = (array: number[]) => {
  return array.length > 0
    ? array.reduce((prev: number, curr: number) => (prev ?? 0) + (curr ?? 0))
    : 0;
};

export const safeAverage = (array: number[]) => {
  return array.length > 0
    ? array.reduce((prev: number, curr: number) => (prev ? prev : 0) + (curr ? curr : 0)) /
        array.length
    : 0;
};

export const safeSd = (array: number[]) => {
  const mean = safeAverage(array);
  return array.length > 0
    ? Math.sqrt(
        array.reduce((prev, curr) => prev + Math.pow(curr - mean, 2), 0) / (array.length - 1)
      )
    : 0;
};

export const safeMax = (array: number[]) =>
  array.reduce((prev, curr) => (prev >= curr ? prev : curr), Number.NEGATIVE_INFINITY);

export const safeLast = (array: number[]) => (array.length !== 0 ? array[array.length - 1] : 0);

export const aggregationFunctions = {
  sum: safeSum,
  average: safeAverage,
  last: safeLast,
};

export const airtableRequiredFields: {
  RawData: {
    [key: string]: (keyof DailyRow)[];
  };
  User: {
    [key: string]: (keyof User)[];
  };
  Venue: {
    [key: string]: (keyof Venue)[];
  };
  YearlyData: {
    [key: string]: (keyof YearlyRow)[];
  };
  IncentiveParams: {
    [key: string]: (keyof IncentiveParam)[];
  };
  IncentiveLocked: {
    [key: string]: (keyof DailyIncentiveLog)[];
  };
  Occasion: {
    [key: string]: (keyof Occasion)[];
  };
} = {
  RawData: {
    dailyRowSalesHistoric: [
      "business_date",
      "total_collection",
      "gst",
      "channel|dine_in|sales",
      "channel|take_out|sales",
      "channel|delivery|sales",
      "channel|events|sales",
      "channel|others|sales",
      "service_charge",
      "product|food|sales",
      "product|beverage|sales",
      "product|condiment|sales",
      "product|miscellaneous|sales",
      "channel|delivery|check_count",
      "channel|take_out|check_count",
      "channel|dine_in|guests",
      "channel|events|guests",
      "channel|others|guests",
      "tips|amount",
    ],
    dailyRowMarketingHistoric: [
      "end_time",
      "business_date",
      "total_collection",
      "gst",
      "discounts",
      "service_charge",
      "channel|dine_in|sales",
      "delivery|oddle|sales",
      "delivery|grabfood|sales",
      "delivery|deliveroo|sales",
      "delivery|foodpanda|sales",
      "channel|take_out|sales",
      "channel|events|sales",
      "channel|others|sales",
      "product|food|sales",
      "product|beverage|sales",
      "product|condiment|sales",
      "product|miscellaneous|sales",
      "channel|dine_in|check_count",
      "delivery|oddle|order_count",
      "delivery|grabfood|order_count",
      "delivery|deliveroo|order_count",
      "delivery|foodpanda|order_count",
      "channel|take_out|check_count",
      "channel|events|check_count",
      "channel|others|check_count",
      "channel|dine_in|guests",
    ],
    dataAssurance: [
      "business_date",
      "service_charge",
      "gst",
      "total_collection",
      "discounts",
      "channel|dine_in|sales",
      "channel|dine_in|guests",
      "channel|dine_in|check_count",
      "channel|take_out|sales",
      "channel|take_out|guests",
      "channel|take_out|check_count",
      "channel|delivery|sales",
      "channel|delivery|guests",
      "channel|delivery|check_count",
      "channel|events|sales",
      "channel|events|guests",
      "channel|events|check_count",
      "channel|others|sales",
      "channel|others|guests",
      "channel|others|check_count",
      "product|food|sales",
      "product|beverage|sales",
      "product|condiment|sales",
      "product|miscellaneous|sales",
      "delivery|deliveroo|sales",
      "delivery|deliveroo|order_count",
      "delivery|foodpanda|sales",
      "delivery|foodpanda|order_count",
      "delivery|grabfood|sales",
      "delivery|grabfood|order_count",
      "delivery|oddle|sales",
      "delivery|oddle|order_count",
    ],
    dataAssuranceExcludedFromTrack: ["business_date"],
    // parameters needed for the calculation of
    // net sales with service charge
    netSalesWithServiceChargeCalc: [
      "business_date",
      "end_time",
      "total_collection",
      "gst",
      "tips|amount",
      "last_modified",
    ],
  },
  User: {
    passwordRaw: ["password_raw"],
    passwordHash: ["password_hash"],
    defaultRedirect: ["default_redirect", "default_venue_name"],
    authDetails: ["password_raw", "password_hash", "default_redirect", "default_venue_name"],
    userPermissionsDashboard: ["permissions_dashboard"],
    userPermissionsVenue: ["permissions_venue"],
  },
  Venue: {
    venue: [
      "name",
      "code",
      "combined_entity_name",
      "accessible_users",
      "data_source",
      "data_source_day_one",
      "incentive_mechanic",
      "incentive_split_mechanic",
    ],
    incentiveMechanic: ["incentive_mechanic", "incentive_split_mechanic"],
    venueLeaders: ["code", "venue_leaders", "venue_leaders_facebook_workplace_id"],
  },
  YearlyData: {
    dataAssurance: [
      "business_date_from",
      "business_date_to",
      "service_charge",
      "gst",
      "total_collection",
      "discounts",
      "channel|dine_in|sales",
      "channel|dine_in|guests",
      "channel|dine_in|check_count",
      "channel|take_out|sales",
      "channel|take_out|guests",
      "channel|take_out|check_count",
      "channel|delivery|sales",
      "channel|delivery|guests",
      "channel|delivery|check_count",
      "channel|events|sales",
      "channel|events|guests",
      "channel|events|check_count",
      "channel|others|sales",
      "channel|others|guests",
      "channel|others|check_count",
      "product|food|sales",
      "product|beverage|sales",
      "product|condiment|sales",
      "product|miscellaneous|sales",
      "delivery|deliveroo|sales",
      "delivery|deliveroo|order_count",
      "delivery|foodpanda|sales",
      "delivery|foodpanda|order_count",
      "delivery|grabfood|sales",
      "delivery|grabfood|order_count",
      "delivery|oddle|sales",
      "delivery|oddle|order_count",
    ],
  },
  IncentiveParams: {
    incentiveParams: ["input_var_type", "input_var_value"],
    incentiveParamsWithDay: ["day", "input_var_type", "input_var_value"],
  },
  IncentiveLocked: {
    potAdditions: ["additionPreTargetPortion", "additionPostTargetPortion"],
  },
  Occasion: {
    occasionInfo: ["date", "occasionName", "type"],
  },
};

export const marketingDashboardAggregationFunctions: {
  [key: string]: AggregationFunction;
} = {
  end_time: "last",
  business_date: "last",
  total_collection: "sum",
  gst: "sum",
  discounts: "sum",
  service_charge: "sum",
  "channel|dine_in|sales": "sum",
  "delivery|oddle|sales": "sum",
  "delivery|grabfood|sales": "sum",
  "delivery|deliveroo|sales": "sum",
  "delivery|foodpanda|sales": "sum",
  "channel|take_out|sales": "sum",
  "channel|events|sales": "sum",
  "channel|others|sales": "sum",
  "product|food|sales": "sum",
  "product|beverage|sales": "sum",
  "product|condiment|sales": "sum",
  "product|miscellaneous|sales": "sum",
  "channel|dine_in|check_count": "sum",
  "delivery|oddle|order_count": "sum",
  "delivery|grabfood|order_count": "sum",
  "delivery|deliveroo|order_count": "sum",
  "delivery|foodpanda|order_count": "sum",
  "channel|take_out|check_count": "sum",
  "channel|events|check_count": "sum",
  "channel|others|check_count": "sum",
  "channel|dine_in|guests": "sum",
};

export const gst = 0.07;

export const assuranceMetrics = ["total_collection", "gst", "service_charge"];

export const logout = (history: H.History<H.LocationState>) => {
  AuthService.logout();
  history.push("/login");
};

export const TlbgConceptCode = {
  "Clink Clink": "CLK",
  Esora: "ESR",
  "Extra Virgin Pizza": "EVP",
  "Le Bon Funk (Club Street)": "LBF",
  Loof: "LOO",
  Odette: "ODT",
  "Over Easy Fullerton": "OEF",
  "Over Easy Orchard": "OEO",
  "Straits Clan": "STC",
  "Tanjong Beach Club": "TBC",
  "The Black Swan": "TBS",
  "The White Rabbit": "TWR",
  "The Red Dragon": "TRD",
  "Grab Kitchen": "GRK",
  "The Coconut Club (Siglap)": "CCS",
  Claudine: "CLD",
  "The Coconut Club (Ann Siang)": "CCA",
  "The Coconut Club (Beach Road)": "CCB",
  "The Coconut Club (River Valley)": "CCR",
  "Le Bon Funk (Holland Village)": "FHV",

  OverEasy: "OEF",
  "The Coconut Club": "CCB",
  "The Warehouse Hotel": "TWH",
  "TLBG HQ - Marketing": "HQ-MKT",
  "TLBG HQ - Group Ops Support": "HQ-GOS",
  "TLBG HQ - Finance": "HQ-FIN",
  "TLBG HQ - Talent": "HQ-TAL",
  "TLBG HQ - Board": "HQ-BOD",
  "HQ - Strategy (Test)": "HQ-STR",

  "Odette Restaurant": "ODT",
  "Claudine Restaurant": "CLD",
  "TLBG HQ": "HQ",
  Fico: "FIC",
  "Bar Bon Funk": "BBF",
  Somma: "SMA",
};

export const getCeilingWoAnomaly = (arr: number[]) => {
  const mean = safeAverage(arr);
  const sd = safeSd(arr);
  const ceilingThreshold = mean + 3 * sd;
  const ceiling = safeMax(arr.filter((val) => val <= ceilingThreshold));

  return ceiling;
};

// Converts a union of two types into an intersection
// i.e. A | B -> A & B
export type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;

// Flattens two union types into a single type with optional values
// i.e. FlattenUnion<{ a: number, c: number } | { b: string, c: number }> = { a?: number, b?: string, c: number }
export type FlattenUnion<T> = {
  [K in keyof UnionToIntersection<T>]: K extends keyof T
    ? T[K] extends any[]
      ? T[K]
      : T[K] extends object
      ? FlattenUnion<T[K]>
      : T[K]
    : UnionToIntersection<T>[K] | undefined;
};

// Example type Person = FlattenUnion<Teacher | Superhero>

export const refreshInterval = 1000 * 60 * 5;

export const percentageValueTransformer: ValueTransformer = {
  backendToFrontend: (input) => input * 100,
  frontendToBackend: (input) => input / 100,
};

export const aggregateDatabaseUpdateResponse = (
  databaseUpdateResponses: DatabaseUpdateResponse[]
) => {
  const metricsToAggregate: (keyof DatabaseUpdateResponse)[] = [
    "rowsAddAttempt",
    "rowsAdded",
    "rowsUpdateAttempt",
    "rowsUpdated",
  ];
  const output: DatabaseUpdateResponse = {};
  metricsToAggregate.forEach((metric) => {
    if (
      metric === "rowsAddAttempt" ||
      metric === "rowsAdded" ||
      metric === "rowsUpdateAttempt" ||
      metric === "rowsUpdated"
    ) {
      const metricArray = databaseUpdateResponses.map((res) => res[metric] ?? 0);
      const aggregatedMetric = safeSum(metricArray);
      output[metric] = aggregatedMetric;
    }
  });

  return output;
};

const numberFormatter = new Intl.NumberFormat("en-SG", { maximumFractionDigits: 2 });

export const formatNumber = (input: number) => numberFormatter.format(input);

export const loggedInUser = () => window.localStorage.getItem("loggedInUser") ?? "";

export const defaultVenue = () => window.localStorage.getItem("defaultVenue") ?? "";

export const standardQuarterLabelSeries: QuarterLabelSeries[] = [
  {
    monthIndices: [0, 3, 6, 9],
  },
  {
    monthIndices: [1, 4, 7, 10],
  },
  {
    monthIndices: [2, 5, 8, 11],
  },
];

export const path = {
  root: "/",
  login: "/login",
  salesDashboard: "/sales_dashboard",
  assuranceDashboard: "/assurance_dashboard",
  marketingDashboard: "/marketing_dashboard",
  setPassword: "/set_password",
  changelog: "/changelog",
  incentivesDashboard: "/partnership_programme",
  incentivesDashboardAdmin: "/partnership_programme_admin",
  groupwideDashboard: "/groupwide_dashboard",
  noUserFound: "/no_user_found",
  userError: "/user_error",
  logout: "/logout",
  permissionDenied: "/permission_denied",
  lightspeedConnection: "/lightspeed_connection",
  salesInput: "/sales_data_input",
  recognitionForm: "/recognition_form",
  recognitionFormSubmitted: "/recognition_form_submitted",
  recognitionDashboard: "/recognition_dashboard",
  manualAdjustmentRequest: "https://airtable.com/shrOgH5U1ww4EHbNr",
  manualAdjustments: "https://airtable.com/shrHaYFat3Z1GN6kA",
  posDataRefresh: "/pos_data_refresh",
  lightspeedApiReauth: "/lightspeed_api_reauth",
};

export const menuTitle = {
  salesDashboard: "Daily Sales Report",
  assuranceDashboard: "Assurance Report",
  marketingDashboard: "Marketing Report",
  setPassword: "Change Password",
  changelog: "Changelog",
  incentivesDashboard: "TLBG Partnership Programme",
  incentivesDashboardAdmin: "TLBG Partnership Programme Admin",
  groupwideDashboard: "Group-wide F&B Daily Sales Report",
  salesDataInput: "Sales Data Input (Manual)",
  recognitionForm: "Open Arms Recognition Form",
  recognitionDashboard: "Open Arms Recognition Dashboard",
  manualAdjustmentRequest: "Manual Adjustment Request",
  manualAdjustments: "Manual Adjustments",
  posDataRefresh: "Manual API Refresh",
  logout: "Logout",
};

export const generateXWwwFormUrlEncodedPayload = (body: { [key: string]: string }) => {
  let output: string[] = [];

  Object.keys(body).map((key) => {
    const encodedKey = encodeURIComponent(key);
    const encodedVal = encodeURIComponent(body[key]);
    output.push(`${encodedKey}=${encodedVal}`);
  });

  return output.join("&");
};

export const compareString = (stringA: string, stringB: string) => {
  if (stringA > stringB) {
    return 1;
  } else if (stringB > stringA) {
    return -1;
  } else {
    return 0;
  }
};

export const weekDayIndexToDow = (weekDayIndex: number): Dow => {
  weekDayIndex = weekDayIndex % 7;

  if (weekDayIndex === 0) {
    return "Sun";
  } else if (weekDayIndex === 1) {
    return "Mon";
  } else if (weekDayIndex === 2) {
    return "Tue";
  } else if (weekDayIndex === 3) {
    return "Wed";
  } else if (weekDayIndex === 4) {
    return "Thu";
  } else if (weekDayIndex === 5) {
    return "Fri";
  } else {
    return "Sat";
  }
};

export const remapNumericKeys = (
  data: {
    [key: number]: any;
  },
  mapper: {
    [key: number]: string;
  }
) => {
  let output: {
    [key: string]: any;
  } = {};

  Object.keys(data).forEach((ogKey) => {
    const numericOgKey = Number(ogKey);
    if (numericOgKey in mapper) {
      output[mapper[numericOgKey]] = data[numericOgKey];
    }
  });

  return output;
};

export enum HttpResponseStatusCode {
  SUCCESS = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
}
